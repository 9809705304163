/*
File Name:DailyQOD.js
Author: 
Modified On: 19/01/2023
Description: Display list of daily qod
API's used:
1) /
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	BackTop,
	Table,
	Input,
	Form,
	Space,
	// Tooltip,
} from "antd";
import "../../../styles/admin.css";
import { fetchAdminApi } from "../../../services/api";
import moment from "moment";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import { CloseOutlined } from '@ant-design/icons';
import Chart from "react-google-charts";
const { Search } = Input;
const DailyQOD = () => {
	const history = useHistory();
	const [dailyQodData,setDailyQodData]=useState([]);
	const [qodGraphData,setQodGraphData]=useState([]);
	const [total,setTotal]=useState(0);
	const [searchValue,setSearchValue]=useState("");
	const [filteredData,setFilteredData]=useState([]);
	useEffect(() => {
		getDailyQodData();
	}, []);
	//Function to get daily Qod data
	const getDailyQodData=()=>{
		fetchAdminApi(`/daily-analysis`, "get").then(
			(res) => {
				if (res && res.data) {
					setDailyQodData(res.data);
					let tempDataLength=res.data.length>30?30:res.data.length;
					let qodDetailsChartData = [
						["Dates", "Correct", "Incorrect","Easy", "Medium", "Difficult", "Not Rated"
						],
					];
					let tempTotalValue=0;
					for (let index = 0; index < tempDataLength; index++) {
						let qodDataRow=[];
						qodDataRow.push(
							moment(res.data[index].dt_last_displayed).format("MM/DD"),
							res.data[index].tot_correct?Number(res.data[index].tot_correct):0,
							res.data[index].tot_incorrect?Number(res.data[index].tot_incorrect):0,
							res.data[index].rated_1?Number(res.data[index].rated_1):0,
							res.data[index].rated_2?Number(res.data[index].rated_2):0,
							res.data[index].rated_3?Number(res.data[index].rated_3):0,
							res.data[index].rated_not?Number(res.data[index].rated_not):0
						);
						tempTotalValue=tempTotalValue+qodDataRow[1]+qodDataRow[2]+qodDataRow[3]+qodDataRow[4]+qodDataRow[5]+qodDataRow[6];
						qodDetailsChartData.push(qodDataRow);
					}
					setTotal(tempTotalValue);
					setQodGraphData(qodDetailsChartData);
					setFilteredData(res.data);
					setSearchValue("");
				} else {
					setDailyQodData([]);
					setQodGraphData([]);
				}
			}
		);
	};
	//Function to convert Number to Alphabets
	// const convertToAlphabet=(value)=>{
	// 	let covertedValue=(value + 9).toString(36).toUpperCase();
	// 	return covertedValue;
	// };
	const dailyQodColumns = [
		{
			title: '#',
			key: 'question_id',
			dataIndex: 'question_id',
			align: 'center',
		},
		{
			title: 'Display',
			key: 'display',
			dataIndex: 'is_qod',
			align: 'center',
			render : (is_qod) => {
				if(is_qod === "f"){
					return <CloseOutlined/>;
				}
			}
		},
		{
			title: 'Date Displayed',
			key: 'dt_last_displayed',
			dataIndex: 'dt_last_displayed',
			align: 'center',
			// width: 150,
			sorter: (a, b) => new Date(a.dt_last_displayed) - new Date(b.dt_last_displayed),
			render: (text) => (
				<span>{text ?moment(text).format(constants.dateFormat) : ""}</span>
			),
		},
		{
			title: 'Question',
			key: 'question_text',
			dataIndex: 'question_text',
			width:350,
			ellipsis: true,
			// render: (record) => {
			// 	return (
			// 		<Tooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: record }}/>} >
			// 			<p dangerouslySetInnerHTML={{ __html: record }}/>
			// 		</Tooltip>
			// 	);
			// }
		},
		{
			title: 'Category',
			key: 'category',
			dataIndex: 'category_abbreviation',
			width:100,
			sorter: (a, b) => a.category_abbreviation.localeCompare(b.category_abbreviation),
			filters: [
				{
					text: 'FPP',
					value: 1,
				},
				{
					text: 'SAED',
					value: 2,
				},
				{
					text: 'PIET',
					value: 3,
				}
			],
			onFilter: (value, record) => parseInt(record?.category_id) === value,
		},
		{
			title: 'Topic',
			key: 'topic',
			dataIndex: 'topic_abbreviation',
			width:100,
			sorter: (a, b) => a.topic_abbreviation.localeCompare(b.topic_abbreviation),
			filters: [
				{
					text: 'ANA',
					value: 2,
				},
				{
					text: 'AP/RT',
					value: 3,
				},
				{
					text: 'AR/PH/LAN',
					value: 4,
				},
				{
					text: 'AUD',
					value: 5,
				},
				{
					text: 'AAC',
					value: 1,
				},
				{
					text: 'CANCER',
					value: 6,
				},
				{
					text: 'CER PALSY',
					value: 7,
				},
				{
					text: 'DEMENTIA',
					value: 16,
				},
				{
					text: 'DEV',
					value: 8,
				},
				{
					text: 'ESL/CU',
					value: 9,
				},
				{
					text: 'FLU',
					value: 10,
				},
				{
					text: 'GEN EVAL',
					value: 11,
				},
				{
					text: 'GEN TREAT',
					value: 12,
				},
				{
					text: 'LAW/ET/GN',
					value: 13,
				},
				{
					text: 'MOT',
					value: 14,
				},
				{
					text: 'MYF',
					value: 15,
				},
				{
					text: 'RES/FOU',
					value: 17,
				},
				{
					text: 'SPECIAL',
					value: 22,
				},
				{
					text: 'SPE',
					value: 18,
				},
				{
					text: 'SWA',
					value: 19,
				},
				{
					text: 'TBI',
					value: 20,
				},
				{	
					text: 'VOICE',
					value: 21,
				}
			],
			onFilter: (value, record) => parseInt(record?.topic_id) === value,
		},
		{
			title: 'Displayed Times',
			key: 'displayed_times',
			dataIndex: 'displayed_times',
			align: 'right',
			render: (text,record) => (
				<span>{parseInt(text)>0 ?<a 
					onClick={()=>
						history.push({
							pathname:"/daily-qod-details",
							state:{ 
								qid:record.question_id ,
								questionText:record.question_text
							} 
						}) 
					}
				>{text}</a> : text}</span>
			),
		},
		{
			title: 'Correct',
			key: 'tot_correct',
			dataIndex: 'tot_correct',
			align: 'right'
		},
		{
			title: 'Incorrect',
			key: 'tot_incorrect',
			dataIndex: 'tot_incorrect',
			align: 'right'
		},
		{
			title: 'Easy',
			key: 'rated_1',
			dataIndex: 'rated_1',
			align: 'right'
		},
		{
			title: 'Medium',
			key: 'rated_2',
			dataIndex: 'rated_2',
			align: 'right'
		},
		{
			title: 'Difficult',
			key: 'rated_3',
			dataIndex: 'rated_3',
			align: 'right'
		},
		{
			title: 'Not Rated',
			key: 'rated_not',
			dataIndex: 'rated_not',
			align: 'right'
		},
	];
	const options = {
		title: "QOD (Last 30 days)",
		height: 400,
		isStacked: true,
		vAxis: {
			title: "Total",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			viewWindow: { min:0 }
		},
		hAxis: { 
			title: "Dates" ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			viewWindow: { min:0 }
		},
		seriesType: "bars",
		series: { 
			2: { type: "line" },
			3: { type: "line" },
			4: { type: "line" },
			5: { type: "line" },
		},
	};
	const noDataAvailableText=()=>{
		return(
			<>							
				<div className="nograph-div">
					<span>No Data Available.</span>
				</div>
			</>
		);
	};
	const onSearch=(e)=>{
		if(e){
			const filterData = filteredData?.filter((fdata) =>
				fdata?.question_text?.toLowerCase().includes(e?.toLowerCase())
			);
			setFilteredData(filterData);
		}else{
			setFilteredData(dailyQodData);
		}
	};
	const onChangeSearchText=(e)=>{
		if(e.target.value){
			setSearchValue(e.target.value);
		}else{
			setSearchValue("");
			setFilteredData(filteredData);
		}
	};
	const handleTableChange = (_pagination, filters) => {
		if (filters.topic || filters.category) {
			let filtered = dailyQodData;
			switch (true) {
			case Boolean(filters.topic && filters.category):
				// Apply both topic and category filters
				filtered = filtered.filter((record) =>
					filters.topic.includes(parseInt(record.topic_id)) &&
						filters.category.includes(parseInt(record?.category_id))
				);
				break;
			case Boolean(filters.topic):
				// Apply topic filter only
				filtered = filtered.filter((record) =>
					filters.topic.includes(parseInt(record.topic_id))
				);
				break;
			case Boolean(filters.category):
				// Apply category filter only
				filtered = filtered.filter((record) =>
					filters.category.includes(parseInt(record?.category_id))
				);
				break;
			default:
				filtered = dailyQodData; // Reset to full data
				break;
			}
			setSearchValue("");
			setFilteredData(filtered);
		} else {
			onSearch(searchValue);
		}		
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					<Breadcrumb>
						<Breadcrumb.Item><a onClick={() => 
							history.push("/admin-dashboard")
						}>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item ><a onClick = {() => 
							history.push("/analysis-menu")
						}>Analysis</a></Breadcrumb.Item>
						<Breadcrumb.Item>Daily QOD</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div>
					<BackTop/>
					<Row gutter={[24, 24]} >
						<Col xs={24} sm={24} md={24} lg={24}>
							<br/>
							<span className="px-3">{ `${filteredData.length !== 0?filteredData.length:0} record(s) found.` }</span>
							<br/>
							<Card size="small" title={<span className="text-uppercase">Latest QOD</span>}
								extra={
									<Form layout="horizontal">
										<Space>
											<Form.Item className="label-default mb-0">
												<Search
													value={searchValue} 
													placeholder="Input search text" 
													onSearch={(e)=>{onSearch(e);}}
													onChange={(e)=>{onChangeSearchText(e);}} 
													enterButton="Search"/>
											</Form.Item>
										</Space>
									</Form>
								}>
								<Table className="admin-gap-table" 
									locale={{ emptyText: 'No Records Found' }}
									dataSource={filteredData} 
									columns={dailyQodColumns}
									onChange={handleTableChange}
									scroll={{ x: 1200 }}
								/>
								{total>0?
									<Chart
										chartType="ComboChart"
										width="100%"
										height="400px"
										data={qodGraphData}
										options={options}
									/>
									:noDataAvailableText()}
							</Card>
						</Col>
					</Row> 
				</div>
			</div>
		</>
	);
};
export default DailyQOD;
